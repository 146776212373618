<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">营销活动</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">邀请好友赢奖励</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >{{ stu == "edit" ? "修改" : "创建" }}活动</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="10rem"
              class
            >
              <el-form-item label="活动名称：" prop="invitedName">
                <el-input
                  size="small"
                  placeholder="请输入活动名称"
                  v-model="ruleForm.invitedName"
                  maxlength="20"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item
                label="活动简介："
                class="form-item form-textarea form-control"
                prop="invitedProfile"
              >
                <el-input
                  size="small"
                  placeholder="请输入活动简介"
                  v-model="ruleForm.invitedProfile"
                  maxlength="40"
                  show-word-limit
                ></el-input>
                <p>
                  <el-popover
                    placement="right-start"
                    width="400"
                    trigger="hover"
                  >
                    <img
                      src="../../assets/activeSet4.png"
                      alt=""
                      style="width: 100%; height: 100%"
                    />
                    <el-button slot="reference" type="text">查看示例</el-button>
                  </el-popover>
                </p>
              </el-form-item>
              <el-form-item
                label="活动日期："
                class="form-item form-textarea"
                required
              >
                <div style="display: flex; align-items: center">
                  <el-form-item class="form-itemDate" prop="startTime">
                    <el-date-picker
                      v-model="ruleForm.startTime"
                      type="datetime"
                      :disabled="!ruleForm.modifyState"
                      size="small"
                      placeholder="选择开始日期"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </el-form-item>

                  至
                  <el-form-item class="form-itemDate" prop="endTime">
                    <el-date-picker
                      v-model="ruleForm.endTime"
                      type="datetime"
                      size="small"
                      placeholder="选择结束日期"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :picker-options="endDatePicker"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-form-item>
              <!-- 奖励积分 -->
              <el-form-item
                label="奖励积分："
                class="form-item form-textarea form-integral"
                prop="person"
              >
                <div class="integral">
                  <span class="integralTitle">注册成功</span>
                  <el-input
                    placeholder="请输入邀请者奖励积分"
                    size="small"
                    maxlength="7"
                    :disabled="!ruleForm.modifyState"
                    v-model="ruleForm.person.registerInvitees"
                    onkeyup="value=value.replace(/\D|^0/g,'')"
                    class="input-with-select"
                  >
                    <span slot="prepend">邀请者</span>
                    <span slot="append">积分</span>
                  </el-input>
                  <el-input
                    placeholder="请输入被邀请者注册成功奖励积分"
                    size="small"
                    maxlength="7"
                    :disabled="!ruleForm.modifyState"
                    v-model="ruleForm.person.registerInvitees1"
                    onkeyup="value=value.replace(/\D|^0/g,'')"
                    class="input-with-select"
                  >
                    <span slot="prepend">被邀请者</span>
                    <span slot="append">积分</span>
                  </el-input>
                </div>
                <div class="integral">
                  <span class="integralTitle">成功入班</span>
                  <el-input
                    placeholder="请输入邀请者奖励积分"
                    size="small"
                    maxlength="7"
                    :disabled="!ruleForm.modifyState"
                    v-model="ruleForm.person.successInvitees"
                    onkeyup="value=value.replace(/\D|^0/g,'')"
                    class="input-with-select"
                  >
                    <span slot="prepend">邀请者</span>
                    <span slot="append">积分</span>
                  </el-input>
                  <el-input
                    placeholder="请输入被邀请者成功入班奖励积分"
                    size="small"
                    maxlength="7"
                    :disabled="!ruleForm.modifyState"
                    v-model="ruleForm.person.successInvitees1"
                    onkeyup="value=value.replace(/\D|^0/g,'')"
                    class="input-with-select"
                  >
                    <span slot="prepend">被邀请者</span>
                    <span slot="append">积分</span>
                  </el-input>
                </div>
                <div class="integral">
                  <span class="integralTitle">完成学习</span>
                  <el-input
                    placeholder="请输入邀请者奖励积分"
                    size="small"
                    maxlength="7"
                    :disabled="!ruleForm.modifyState"
                    v-model="ruleForm.person.finishInvitees"
                    onkeyup="value=value.replace(/\D|^0/g,'')"
                    class="input-with-select"
                  >
                    <span slot="prepend">邀请者</span>
                    <span slot="append">积分</span>
                  </el-input>
                  <el-input
                    placeholder="请输入被邀请者完成学习奖励积分"
                    size="small"
                    maxlength="7"
                    :disabled="!ruleForm.modifyState"
                    v-model="ruleForm.person.finishInvitees1"
                    onkeyup="value=value.replace(/\D|^0/g,'')"
                    class="input-with-select"
                  >
                    <span slot="prepend">被邀请者</span>
                    <span slot="append">积分</span>
                  </el-input>
                </div>
                <div class="integral" style="flex-direction: column;align-items: flex-start;">
                  <div style="display: flex;align-items: center;flex: 1;width: 100%;">
                  <span class="integralTitle">购买题库</span>
                  <el-input
                    placeholder="请输入邀请者奖励积分"
                    size="small"
                    maxlength="7"
                    :disabled="!ruleForm.modifyState"
                    v-model="ruleForm.person.questionBank"
                    onkeyup="value=value.replace(/\D|^0/g,'')"
                    class="input-with-select"
                  >
                    <span slot="prepend">邀请者</span>
                    <span slot="append">积分</span>
                  </el-input>
                  <el-input
                    placeholder="请输入被邀请者购买题库奖励积分"
                    size="small"
                    maxlength="7"
                    :disabled="!ruleForm.modifyState"
                    v-model="ruleForm.person.questionBank1"
                    onkeyup="value=value.replace(/\D|^0/g,'')"
                    class="input-with-select"
                    style="padding-left: 1.5rem"
                  >
                    <span slot="prepend">被邀请者</span>
                    <span slot="append">积分</span>
                  </el-input>
                </div>
                  <p style="padding-left:4rem">当被邀请者购买题库(必须是现金支付)时,邀请者和被邀请者可获得积分奖励。</p>
                </div>
                <div class="integral" style="flex-direction: column;align-items: flex-start;">
                  <div style="display: flex;align-items: center;flex: 1;width: 100%;">
                  <span class="integralTitle">购买课程</span>
                  <el-input
                    placeholder="请输入邀请者奖励积分"
                    size="small"
                    maxlength="7"
                    :disabled="!ruleForm.modifyState"
                    v-model="ruleForm.person.curriculum"
                    onkeyup="value=value.replace(/\D|^0/g,'')"
                    class="input-with-select"
                  >
                    <span slot="prepend">邀请者</span>
                    <span slot="append">积分</span>
                  </el-input>
                  <el-input
                    placeholder="请输入被邀请者购买课程奖励积分"
                    size="small"
                    maxlength="7"
                    :disabled="!ruleForm.modifyState"
                    v-model="ruleForm.person.curriculum1"
                    onkeyup="value=value.replace(/\D|^0/g,'')"
                    class="input-with-select"
                    style="padding-left: 1.5rem"
                  >
                    <span slot="prepend">被邀请者</span>
                    <span slot="append">积分</span>
                  </el-input>
                  </div>
                  <p style="padding-left:4rem">当被邀请者购买课程(必须是现金支付)时,邀请者和被邀请者可获得积分奖励。</p>
                </div>
                <p>至少填写1种奖励积分，积分必须大于0，不填代表不奖励。</p>
              </el-form-item>
              <el-form-item
                label="活动范围："
                class="form-item form-textarea"
                prop="areaId"
              >
                <el-cascader
                  v-model="ruleForm.areaId"
                  placeholder="请选择活动范围"
                  :options="areatreeList"
                  :props="propsarea"
                  size="small"
                  :disabled="!ruleForm.modifyState"
                  @change="handleChange"
                ></el-cascader>
                <p>
                  只有成功加入该活动范围内的班级才可领到积分,并且只有定位在该范围的用户才可以在活动列表看到该活动。
                </p>
              </el-form-item>
              <el-form-item
                label="培训类型："
                class="form-item form-textarea"
                prop="trainTypeId"
              >
                <el-cascader
                  placeholder="请选择培训类型"
                  v-model="ruleForm.trainTypeId"
                  :options="trainTypeList"
                  :disabled="!ruleForm.modifyState"
                  :props="propsTrainType"
                  @change="handleChangetrainType"
                  size="small"
                ></el-cascader>
                <p>只有成功入该培训类型的班才会获得积分。</p>
              </el-form-item>
              <el-form-item
                label="上传banner图："
                prop="thumbnail"
                class="form-control"
              >
                <el-upload
                  :on-change="handlebusinessLicense2"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
                <p>
                  最多只能上传1张,建议上传尺寸750*502px,分辨率72dpi
                  <el-popover
                    placement="right-start"
                    width="400"
                    trigger="hover"
                  >
                    <img
                      src="../../assets/activeSet1.png"
                      alt=""
                      style="width: 100%; height: 100%"
                    />
                    <el-button slot="reference" type="text">查看示例</el-button>
                  </el-popover>
                </p>
              </el-form-item>
              <el-form-item
                label="背景色"
                class="form-item form-textarea"
                prop="backgroundColor"
              >
                <el-color-picker
                  v-model="ruleForm.backgroundColor"
                ></el-color-picker>
              </el-form-item>
               <el-form-item
                label="上传流程图："
                prop="thumbnail"
                class="form-control"
              >
                <el-upload
                  :on-change="handlebusinessLicense3"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="ruleForm.thumbnail1 || require('@/assets/develop.png')"
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
                <p>
                  最多只能上传1张,建议上传尺寸750*502px,分辨率72dpi
                  <el-popover
                    placement="right-start"
                    width="400"
                    trigger="hover"
                  >
                    <img
                      src="../../assets/activeSet7.png"
                      alt=""
                      style="width: 100%; height: 100%"
                    />
                    <el-button slot="reference" type="text">查看示例</el-button>
                  </el-popover>
                </p>
              </el-form-item>
              <el-form-item
                label="活动说明："
                class="form-item form-textarea form-control"
                prop="activityDescription"
              >
                <!-- <div ref="editor" class="editor" style="width: 100%" /> -->
                <el-input
                  type="textarea"
                  resize="none"
                  v-model="ruleForm.activityDescription"
                  class="input_w"
                  :rows="6"
                ></el-input>
                <p>
                  <el-popover
                    placement="right-start"
                    width="400"
                    trigger="hover"
                  >
                    <img
                      src="../../assets/activeSet5.png"
                      alt=""
                      style="width: 100%; height: 100%"
                    />
                    <el-button slot="reference" type="text">查看示例</el-button>
                  </el-popover>
                </p>
              </el-form-item>
              <el-form-item
                label="积分未到账原因："
                class="form-item form-textarea form-control"
                prop="integralDescription"
              >
                <!-- <div ref="editor" class="editor" style="width: 100%" /> -->
                <el-input
                  type="textarea"
                  resize="none"
                  v-model="ruleForm.integralDescription"
                  class="input_w"
                  :rows="6"
                ></el-input>
                <p>
                  <el-popover
                    placement="right-start"
                    width="400"
                    trigger="hover"
                  >
                    <img
                      src="../../assets/activeSet6.png"
                      alt=""
                      style="width: 100%; height: 100%"
                    />
                    <el-button slot="reference" type="text">查看示例</el-button>
                  </el-popover>
                </p>
              </el-form-item>
              <el-form-item
                label="是否上架："
                class="form-item form-textarea"
                prop="IsShelf"
              >
                <el-select
                  v-model="ruleForm.IsShelf"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in shelfList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="activeSetbtn">
                <el-button class="bgc-bv" @click="doCancel">取消</el-button>
                <el-button class="bgc-bv" @click="doSure">确定</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import E from "wangeditor";

let editor = {};
export default {
  name: "CreateActivity",
  components: {},
  mixins: [List],
  data() {
    //积分校验
    let validateCode = (rule, value, callback) => {
      let str =
        value.registerInvitees +
          value.registerInvitees1 +
          value.successInvitees +
          value.successInvitees1 +
          value.finishInvitees +
          value.finishInvitees1 +
          value.questionBank +
          value.questionBank1 +
          value.curriculum +
          value.curriculum1 || "";
      if (str == "") {
        callback(new Error("请至少输入一项"));
      } else {
        callback();
      }
    };
    let validates = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入活动日期"));
      } else if (value == this.ruleForm.startTime) {
        callback(new Error("活动结束时间不能和活动开始时间相同"));
      } else {
        callback();
      }
    };
    return {
      cityTree: [],
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        disabled: "disabled",
        emitPath: false,
        checkStrictly: false,
        multiple: true,
      },
      Tree: [],
      trainTypeList: [],
      propsTrainType: {
        value: "id",
        label: "label",
        emitPath: false,
        checkStrictly: false,
        multiple: true,
      },
      shelfList: [
        {
          value: true,
          label: "上架",
        },
        {
          value: false,
          label: "下架",
        },
      ],
      ruleForm: {
        invitedName: "", //活动名称
        invitedProfile: "", //活动简介
        startTime: "",
        endTime: "",
        // activityDate: {
        //   startTime: "",
        //   endTime: "",
        // }, //活动日期
        areaId: [], //活动范围
        trainTypeId: [], //培训类型
        thumbnail: "", //banner图
        activityDescription: "", //活动说明
        integralDescription: "", //积分未到账原因
        thumbnailId: "",
        IsShelf: false,
        person: {
          registerInvitees: "",
          registerInvitees1: "",
          successInvitees: "",
          successInvitees1: "",
          finishInvitees: "",
          finishInvitees1: "",
          questionBank: "",
          questionBank1: "",
          curriculum: "",
          curriculum1: "",
        },
        backgroundColor: "",
        modifyState: true,
        thumbnail1:'',
        thumbnailId1:'',
      },
      rules: {
        invitedName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        invitedProfile: [
          { required: true, message: "请输入活动简介", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择活动范围", trigger: "change" },
        ],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "请选择结束日期", trigger: "blur" },
          { required: true, validator: validates, trigger: "change" },
        ],
        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        thumbnail: [
          { required: true, message: "请上传banner图片", trigger: "change" },
        ],
        backgroundColor: [
          { required: true, message: "请选择背景色", trigger: "change" },
        ],
        activityDescription: [
          { required: true, message: "请输入活动说明", trigger: "blur" },
        ],
        integralDescription: [
          { required: true, message: "请输入积分未到账原因", trigger: "blur" },
        ],
        IsShelf: [
          { required: true, message: "请选择是否上架", trigger: "change" },
        ],
        person: [
          { required: true, validator: validateCode, trigger: "change" },
        ],
      },
      startDatePicker: this.beginDate(),
      endDatePicker: this.processDate(),
    };
  },
  created() {
    this.stu = this.$route.query.stu;
    if (this.stu == "edit") {
      this.invitedId = this.$route.query.invitedId;
      this.getDetails(this.invitedId);
    }
    this.getareatree();
    this.getTrainTypeList();
  },
  computed: {},
  mounted() {
    // this.editorInit();
    // const eWidth = this.$refs.editor.clientWidth;
    // this.eWidth = eWidth;
  },
  methods: {
    beginDate() {
      let self = this;
      return {
        disabledDate(time) {
          return time.getTime() > Date.now(); //开始时间不选时，结束时间最大值小于等于当天
        },
      };
    },
    //提出结束时间必须大于提出开始时间
    processDate() {
      let self = this;
      return {
        disabledDate(time) {
          if (self.ruleForm.startTime) {
            return (
              new Date(self.ruleForm.startTime).getTime() > time.getTime() ||
              time.getTime() >= self.ruleForm.startTime
            );
          } else {
            return time.getTime() >= self.ruleForm.startTime; //开始时间不选时，结束时间最大值小于等于当天
          }
        },
      };
    },
    getareatree() {
      this.$post("/sys/area/tree?areaLevel=2").then((ret) => {
        let data = ret.data || [];
        data.map((el) => {
          el.disabled = false;
        });
        this.areatreeList =
          [{ label: "全部区域", value: "all", disabled: false }, ...data] || [];
      });
    },
    handleChange(val) {
      let self = this;
      if (val.length > 0) {
        val.map((element) => {
          console.log(element);
          if (element == "all") {
            self.areatreeList.map((el) => {
              if (el.value != "all") {
                el.disabled = true;
              } else {
                el.disabled = false;
              }
            });
          } else {
            self.areatreeList.map((el) => {
              if (el.value == "all") {
                el.disabled = true;
              }
            });
          }
        });
      } else {
        self.areatreeList.map((el) => {
          el.disabled = false;
        });
      }
    },

    handleChangetrainType(val) {
      let self = this;
      if (val.length > 0) {
        val.map((element) => {
          if (element == "all") {
            self.trainTypeList.map((el) => {
              if (el.id != "all") {
                el.disabled = true;
              } else {
                el.disabled = false;
              }
            });
          } else {
            self.trainTypeList.map((el) => {
              if (el.id == "all") {
                el.disabled = true;
              }
            });
          }
        });
      } else {
        self.trainTypeList.map((el) => {
          el.disabled = false;
        });
      }
    },
    /**
     * tree接口
     */
    getTrainTypeList() {
      this.$post("/sys/category/train-type/tree")
        .then((res) => {
          let data = res.data || [];
          data.map((el) => {
            el.disabled = false;
          });
          this.trainTypeList =
            [{ label: "全部", id: "all", disabled: false }, ...data] || [];
        })
        .catch((err) => {
          return;
        });
    },
    /* 上传banner图 */
    handlebusinessLicense2(res) {
      this.uploadFetch(res.raw);
    },
    uploadFetch(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.thumbnail = ret.data.fileURL || "";
        that.ruleForm.thumbnailId = ret.data.fileKey || "";
      });
    },
    /* 上传流程图 */
    handlebusinessLicense3(res) {
      this.uploadFetch1(res.raw);
    },
    uploadFetch1(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.thumbnail1 = ret.data.fileURL || "";
        that.ruleForm.thumbnailId1 = ret.data.fileKey || "";
      });
    },
    getDetails(invitedId) {
      this.$post(
        "/invited/act-invited/getInvitedInfo",
        {
          invitedId,
        },
        3000,
        true,
        2
      )
        .then((ret) => {
          console.log(ret.data.invitedName);
          let rules = ret.data.actInvitedRuleList;
          this.ruleForm = {
            ...this.ruleForm,
            invitedName: ret.data.invitedName,
            invitedProfile: ret.data.invitedProfile,
            startTime: ret.data.startTime.replaceAll("/", "-"),
            endTime: ret.data.endTime.replaceAll("/", "-"),
            thumbnail: ret.data.invitedBannerUrl,
            thumbnailId: ret.data.invitedBanner,
            thumbnail1: ret.data.flowChartUrl,
            thumbnailId1: ret.data.flowChart,
            areaId: ret.data.invitedArea.split(","),
            trainTypeId: ret.data.trainTypeId.split(","),
            IsShelf: ret.data.validState,
            modifyState: ret.data.modifyState,
            activityDescription: ret.data.invitedDescribe.replace(
              /sunrealbr/g,
              "\n"
            ),
            integralDescription: ret.data.scoreDesc.replace(
              /sunrealbr/g,
              "\n"
            ),
            backgroundColor: ret.data.backgroundColor,
          };
          // editor.txt.html(ret.data.invitedDescribe);
          rules.map((el) => {
            if (el.rewardType == "10") {
              this.ruleForm.person.registerInvitees =
                Number(el.inviterScore) || "";
              this.ruleForm.person.registerInvitees1 =
                Number(el.inviteeScore) || "";
              this.invitedId = ret.data.invitedId;
              this.rewardId = ret.data.rewardId;
            } else if (el.rewardType == "20") {
              this.ruleForm.person.successInvitees =
                Number(el.inviterScore) || "";
              this.ruleForm.person.successInvitees1 =
                Number(el.inviteeScore) || "";
              this.invitedId = ret.data.invitedId;
              this.rewardId = ret.data.rewardId;
            } else if (el.rewardType == "30") {
              this.ruleForm.person.finishInvitees =
                Number(el.inviterScore) || "";
              this.ruleForm.person.finishInvitees1 =
                Number(el.inviteeScore) || "";
              this.invitedId = ret.data.invitedId;
              this.rewardId = ret.data.rewardId;
            }else if (el.rewardType == "40") {
              this.ruleForm.person.questionBank =
                Number(el.inviterScore) || "";
              this.ruleForm.person.questionBank1 =
                Number(el.inviteeScore) || "";
              this.invitedId = ret.data.invitedId;
              this.rewardId = ret.data.rewardId;
            }else if (el.rewardType == "50") {
              this.ruleForm.person.curriculum =
                Number(el.inviterScore) || "";
              this.ruleForm.person.curriculum1 =
                Number(el.inviteeScore) || "";
              this.invitedId = ret.data.invitedId;
              this.rewardId = ret.data.rewardId;
            }
          });
          this.ruleForm.areaId.map((els) => {
            if (els == "all") {
              this.areatreeList.map((el) => {
                if (el.value == "all") {
                  el.disabled = false;
                } else {
                  el.disabled = true;
                }
              });
            } else {
              this.areatreeList.map((el) => {
                if (el.value == "all") {
                  el.disabled = true;
                }
              });
            }
          });
          this.ruleForm.trainTypeId.map((els) => {
            if (els == "all") {
              this.trainTypeList.map((el) => {
                if (el.id == "all") {
                  el.disabled = false;
                } else {
                  el.disabled = true;
                }
              });
            } else {
              this.trainTypeList.map((el) => {
                if (el.id == "all") {
                  el.disabled = true;
                }
              });
            }
          });
        })
        .catch((err) => {
          return;
        });
    },
    /* 确定 */
    doSure() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //接口
          let params = {
            invited: {},
            rules: [],
          };
          params.invited = {
            invitedName: this.ruleForm.invitedName,
            invitedProfile: this.ruleForm.invitedProfile,
            startTime: this.ruleForm.startTime,
            endTime: this.ruleForm.endTime,
            invitedArea: this.ruleForm.areaId.toString(),
            trainTypeId: this.ruleForm.trainTypeId.toString(),
            invitedBanner: this.ruleForm.thumbnailId,
            flowChart: this.ruleForm.thumbnailId1, //流程图
            invitedDescribe: this.ruleForm.activityDescription,
            scoreDesc: this.ruleForm.integralDescription, //积分未到账原因
            validState: this.ruleForm.IsShelf,
            backgroundColor: this.ruleForm.backgroundColor,
          };
          //注册成功
          params.rules.push({
            inviterScore: this.ruleForm.person.registerInvitees, //邀请者积分
            inviteeScore: this.ruleForm.person.registerInvitees1, //被邀请者积分
            rewardType: "10",
            invitedId: this.invitedId || "",
            rewardId: this.rewardId || "",
          });
          //成功入班
          params.rules.push({
            inviterScore: this.ruleForm.person.successInvitees, //邀请者积分
            inviteeScore: this.ruleForm.person.successInvitees1, //被邀请者积分
            rewardType: "20",
            invitedId: this.invitedId || "",
            rewardId: this.rewardId || "",
          });
          //完成学习
          params.rules.push({
            inviterScore: this.ruleForm.person.finishInvitees, //邀请者积分
            inviteeScore: this.ruleForm.person.finishInvitees1, //被邀请者积分
            rewardType: "30",
            invitedId: this.invitedId || "",
            rewardId: this.rewardId || "",
          });
          //购买题库
          params.rules.push({
            inviterScore: this.ruleForm.person.questionBank, //邀请者积分
            inviteeScore: this.ruleForm.person.questionBank1, //被邀请者积分
            rewardType: "40",
            invitedId: this.invitedId || "",
            rewardId: this.rewardId || "",
          });
          //购买课程
          params.rules.push({
            inviterScore: this.ruleForm.person.curriculum, //邀请者积分
            inviteeScore: this.ruleForm.person.curriculum1, //被邀请者积分
            rewardType: "50",
            invitedId: this.invitedId || "",
            rewardId: this.rewardId || "",
          });
          if (this.invitedId) {
            params.invited.invitedId = this.invitedId;
          }

          this.$post(
            this.stu == "add"
              ? "/invited/act-invited/insert"
              : "/invited/act-invited/modify",
            {
              ...params,
              invited: {
                ...params.invited,
                invitedDescribe: this.ruleForm.activityDescription.replace(
                  /\n/g,
                  "sunrealbr"
                ),
                scoreDesc: this.ruleForm.integralDescription.replace(
                  /\n/g,
                  "sunrealbr"
                ),
              },
            },
            3000,
            true,
            2
          )
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  message: this.stu == "edit" ? "修改成功" : "创建成功",
                  type: "success",
                });
                this.$router.push({
                  path: "/web/InviteFriendsToWinAwardsList",
                  query: {
                    refresh: true,
                  },
                });
              }
            })
            .catch((err) => {
              return;
            });
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
    /* 取消 */
    doCancel() {
      this.$router.push({
        path: "/web/InviteFriendsToWinAwardsList",
      });
    },
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "justify", // 对齐方式
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = (html) => {
        this.ruleForm.activityDescription = html;
      };
      editor.config.pasteTextHandle = function(content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
  },
};
</script>
<style lang="less" scoped>
.form-textarea {
  /deep/.el-textarea {
    /deep/ .el-textarea__inner {
      min-height: 13rem !important;
      resize: none;
    }
  }
}
.activeSetbtn {
  display: flex;
  justify-content: center;
  padding-bottom: 0.75rem;
}
.integral {
  display: flex;
  margin-bottom: 0.75rem;
  align-items: center;
  .integralTitle {
    min-width: 4rem;
  }
  > div:nth-child(3) {
    padding-left: 1.5rem;
  }
}
// .el-form-item {
//   display: flex;
//   align-items: center;
//   .el-form-item__content {
//     width: 100% !important;
//     margin-left: 0 !important;
//   }
// }
.form-control {
  align-items: flex-start;
}
.form-integral {
  align-items: flex-start;
  .form-integralbox {
    padding-bottom: 0.75rem;
  }
}
.form-itemDate {
  width: 50%;
}
</style>
<style lang="less">
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
</style>
